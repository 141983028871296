<template>
  <div>
    <a @click="loginSocialite('google')" class="btn btn-google text-center text-white w-100 my-2">
      <a-icon type="google" />
      <strong> Google</strong>
    </a>
<!--    <a @click="loginSocialite('github')" class="btn btn-github text-center text-white w-100 my-2">-->
<!--      <a-icon type="github" />-->
<!--      <strong> GitHub</strong>-->
<!--    </a>-->
  </div>
</template>

<script>
export default {
  name: 'socialiteButtons',
  methods: {
    loginSocialite(provider) {
      this.$store.dispatch('user/SOCIALITE_LOGIN', { payload: provider })
    },
  },
}
</script>

<style scoped>

</style>
