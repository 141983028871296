<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Inicio de Sesión</div>
      <a-form class="mb-4" :form="form" @submit="handleSubmit">
        <template v-if="['development','quality'].includes(environment)">
          <a-form-item>
            <a-input
              size="large"
              placeholder="Correo electrónico"
              v-decorator="['email', { rules: [{ required: true, message: 'Ingrese su Usuario' }]}]"
            />
          </a-form-item>
          <a-form-item>
            <a-input-password placeholder="Contraseña"
                              size="large"
                              autocomplete="off"
                              v-decorator="['password', {rules: [{ required: true, message: 'Ingrese su contraseña' }]}]"/>
          </a-form-item>
          <a-button type="primary" htmlType="submit" size="large"
                    class="text-center w-100" :loading="loading">
            <strong>Ingresar</strong>
          </a-button>
        </template>
        <socialite-buttons/>
      </a-form>
      <router-link to="/auth/forgot-password" class="kit__utils__link font-size-16">¿Olvidaste tu contraseña?</router-link>
    </div>
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">¿No tienes una cuenta?</span>
      <router-link to="/auth/register" class="kit__utils__link font-size-16">Registrarse</router-link>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import socialiteButtons from '../socialiteButtons/socialiteButtons'
export default {
  name: 'login',
  components: {
    socialiteButtons,
  },
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
  },
  data: function () {
    return {
      environment: process.env.VUE_APP_ENVIRONMENT,
      form: this.$form.createForm(this),
    }
  },
  methods: {
    changeAuthProvider(value) {
      this.$store.commit('CHANGE_SETTING', { setting: 'authProvider', value })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('user/LOGIN', { payload: values })
        }
      })
    },
    loginSocialite(provider) {
      this.$store.dispatch('user/SOCIALITE_LOGIN', { payload: provider })
    },
  },
}
</script>
<style lang="scss" module>
  @import "@/@airui/system/Auth/style.module.scss";
</style>
